$c1: white;
$c2: black;
$hover: #e9be00;
$cfooter: #1f1f1f;
$cabout: #f4f2f2;
$svgwidth: 30px;
$svgheight: 30px;
$topmenucolor: rgba(0, 0, 0, 0.6);
$square-color: rgb(240, 240, 240);
$toprectangle: linear-gradient(
   to bottom,
   rgba(140, 140, 140, 0.3) 0%,
   rgba(140, 140, 140, 0.3) 100%
);
$rectangle: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 0.77) 0%,
   rgba(255, 255, 255, 0.77) 100%
);
$rectangle3: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 0.7) 0%,
   rgba(255, 255, 255, 0.7) 100%
);
$rectangle2: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 0.77) 0%,
   rgba(255, 255, 255, 0.77) 100%
);
%section-title {
   text-align: center;
   margin-bottom: 25px;
   font-weight: 700;
   font-size: 24px;
}
%section-paragraph {
   margin-bottom: 25px;
   line-height: 1.6;
   font-weight: 400;
}
%section {
   padding-top: 30px;
   padding-bottom: 30px;
}
%svg {
   width: $svgwidth;
   max-height: $svgheight;
}

%button {
   text-decoration: none !important;
   text-transform: uppercase;
   color: black;
   // margin: 0 auto;
   $width: 180px;
   $height: 40px;
   width: $width;
   height: $height;
   $border: 1px;
   border: solid black $border;
   display: block;
   text-align: center;
   line-height: $height - 2 * $border;
   border-radius: 5px;
   margin: 35px auto 5px;
   transition: linear 0.2s;
   &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      color: $hover;
   }
}
