@import "settings";
@import url("https://fonts.googleapis.com/css?family=Oswald:400,700&subset=latin-ext");
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
   margin: 0;
   padding: 0;
}
a {
   color: inherit;
   text-decoration: none;
   &:visited {
      color: inherit;
   }
   &:hover {
      text-decoration: none;
   }
}
::selection {
   color: $hover;
   background-color: rgba(0, 0, 0, 0.8);
}
.container {
   max-width: 2000px;
}

.svg svg {
   height: 27px;
   width: 27px;
}
.alink {
   display: flex;
   align-items: center;
   transition: linear 0.2s all;
   &:hover {
      color: $hover;
   }
}
.big-img {
   display: block;
   height: auto;
   width: 100%;
}
body {
   font-family: "Oswald", cursive;
   font-size: 18px;
   box-sizing: border-box;
}
.section-title {
   @extend %section-title;
}

.section-paragraph {
   @extend %section-paragraph;
}
.svg {
   @extend %svg;
}
.section {
   @extend %section;
}
.castle {
   @extend %section;
}
.button {
   @extend %button;
}
.icon-p {
   margin-left: 15px;
   font-size: 16px;
}
$container-max-widths: (
   sm: 540px,
   md: 720px,
   lg: 960px,
   xl: 1600px
);
.container-main {
   margin: 0 auto;
}
.main-about,
.main-gallery,
.main-contact {
   margin: 0 auto;
}
.active {
   color: $hover !important;
}
.castle {
   padding-top: 0 !important;
}
// -----------------------------------MENU---------------------------------------
.menu {
   background-color: $topmenucolor;
   height: 60px;
   width: 100%;
   position: absolute;
   z-index: 102;
   &.fixed {
      position: fixed;
      margin: 0;
      background: linear-gradient(
         to bottom,
         rgba(0, 0, 0, 0.7) 0%,
         rgba(0, 0, 0, 0.7) 100%
      );
   }
}
.top {
   position: absolute;
   z-index: 99;
   width: 100%;
   display: flex;
   justify-content: center;
   .contact-icons,
   .social-icons {
      display: none;
   }
   .logo {
      text-decoration: none;
      color: white;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      span {
         font-size: 18px;
         font-weight: 300;
      }
      p {
         padding-top: 8px;
         line-height: 0.9;
      }
   }
}
// -----------------------HAMBURGER------------------------
.navicon {
   position: absolute;
   top: 16px;
   right: 15px;
   height: 40px;
   width: 35px;
   z-index: 99;
   transition: linear 0.5s all;
   &:before,
   &:after {
      background: white;
      content: "";
      height: 3px;
      left: 0;
      transition: 0.8s ease;
      width: 35px;
      border-radius: 2px;
   }
   &:before {
      box-shadow: white 0 12px 0 0;
      position: absolute;
      top: 0;
   }
   &:after {
      position: absolute;
      top: 24px;
   }
   &--active {
      transition: linear 0.5s all;
      &:before {
         box-shadow: transparent 0 0 0 0;
         top: 15px;
         transform: rotate(225deg);
      }
      &:after {
         top: 15px;
         transform: rotate(315deg);
      }
   }
}
// -------------------------------OPEN-MENU----------------------------
.toggle {
   display: flex;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: linear-gradient(
         to bottom,
         rgba(0, 0, 0, 0.6) 0%,
         rgba(0, 0, 0, 0.6) 100%
      ),
      url(../img/main2.jpg) 25%;
   background-size: cover;
   display: block;
   opacity: 0;
   transition: ease-in 0.5s opacity;
   transform: translateY(-200%);
   &.fixed {
      position: fixed;
      margin: 0;
      background: linear-gradient(
         to bottom,
         rgba(0, 0, 0, 0.7) 0%,
         rgba(0, 0, 0, 0.7) 100%
      );
   }
   &--active {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      opacity: 1;
      transition: ease-in 0.5s opacity;
      transform: translateY(0);
   }
   &__menu {
      padding: 0;
      margin: 0;
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      font-size: 30px;
      li {
         width: 40%;
         display: block;
         margin: 0 auto;
         margin-bottom: 10px;
         border-bottom: 1px solid rgba(255, 255, 255, 0.2);
         a {
            text-decoration: none;
            color: white;
            display: block;
            text-align: center;
            font-size: 22px;
            transition: linear 0.2s all;
            padding: 5px 0;
            &:hover {
               color: $hover;
            }
         }
      }
   }
   .submenu {
      color: white;
      font-size: 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .facebook {
         margin-bottom: 5px;
      }
      .message div {
         display: flex;
         align-items: center;
         .fa-envelope {
            margin-right: 10px;
         }
      }
      .telephone {
         margin-bottom: 2px;
         div {
            display: flex;
            align-items: center;
            .fa-phone {
               margin-right: 10px;
               transform: rotateY(180deg);
            }
         }
      }
   }
}
// -------------------------------------STRONA GŁÓWNA--------------------------
.main-home {
   height: 100vh;
   color: white;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: center;
   .scroll {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 5px 0;
      font-weight: 700;
      font-size: 12px;
      position: relative;
      align-items: center;
      z-index: 98;
      overflow: hidden;
      &-icon {
         height: 13px;
         overflow: hidden;
         .fa-angle-down {
            margin: 0 10px;
            animation: scroll 1.8s infinite linear;
         }
      }
   }
   // -------------------------------SLIDESHOW-------------------------------
   .slideshow,
   .slideshow:after {
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0px;
      left: 0px;
      z-index: 0;
      list-style: none;
      overflow: hidden;
   }
   .slideshow {
      .slideimage {
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0px;
         left: 0px;
         right: 0;
         bottom: 0;
         color: transparent;
         background-size: cover;
         background-position: 50% 50%;
         background-repeat: none;
         opacity: 0;
         z-index: 0;
         animation: imageAnimation 18s linear infinite 0s;
      }
      .main-text {
         z-index: 1000;
         position: absolute;
         top: 50%;
         transform: translateY(-50%) translateX(-50%);
         left: 50%;
         width: 80%;
         margin: 0 auto;
         text-align: center;
         opacity: 0;
         color: #fff;
         font-size: 30px;
         font-weight: 700;
         animation: titleAnimation 18s linear infinite 0s;
         letter-spacing: 2px;
         &-second {
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 1px;
         }
      }
      .main-text2 {
         animation-delay: 6s;
      }
      .main-text3 {
         animation-delay: 12s;
      }
      .slideimage1 {
         background: $toprectangle, url(../img/main.jpg) 25%;
         background-size: cover;
      }
      .slideimage2 {
         background: $toprectangle, url(../img/main2.jpg) 25%;
         background-size: cover;
         animation-delay: 6s;
      }
      .slideimage3 {
         background: $toprectangle, url(../img/main3.jpg) 25%;
         background-size: cover;
         animation-delay: 12s;
      }
   }
}
// ----------------------------O-NAS---------------------------
.main-about {
   .svg {
      width: 33px;
      max-height: 33px;
   }
   .about-svg {
      padding-top: 10px;
      .button {
         margin: 0;
      }
   }
   .golf,
   .horse,
   .castle,
   .fish {
      display: flex;
      justify-content: center;
      align-items: flex-start;
   }
}
// ------------------------POKOJE-------------------
.main-room {
   background: $rectangle, url(../img/pokoje.jpg);
   background-size: cover;
   background-position: 45%;
   background-attachment: fixed;
   .room-icon {
      display: flex;
      height: $svgwidth;
      align-items: center;
      margin-bottom: 25px;
   }
   .section-paragraph {
      margin-bottom: 30px;
   }
   .button {
      margin-top: 5px;
   }
}
// -------------------------------GALERIA-----------
.main-gallery-img.main-gallery {
   padding-top: 0;
}
.main-gallery-text.main-gallery {
   padding-bottom: 0;
}
.main-atraction-img {
   padding-top: 10px;
}
.main-gallery {
   .item img {
      height: auto;
      width: 100%;
      display: block;
      margin: 0 auto;
   }
   .item-v img {
      height: auto;
      width: 56%;
      display: block;
      margin: 0 auto;
   }
   .gallery-paragraph {
      text-align: center;
      margin: 35px 0 5px;
      font-size: 16px;
      padding: 5px 10px;
      border: 1px solid black;
      border-radius: 5px;
      display: inline-block;
   }
   .gallery-signature {
      display: flex;
      justify-content: center;
   }
   .section-title {
      margin-bottom: 30px;
   }
   .container.gallery {
      max-width: 1500px;
   }
}
// ---------------------------------------ATRAKCJE------------
.main-atraction {
   .room-icon {
      display: flex;
      height: $svgwidth;
      align-items: center;
      margin-bottom: 25px;
   }
   .section-paragraph {
      margin-bottom: 30px;
   }
   .button {
      margin-top: 5px;
   }
}
// -------------------------------KONTAKT--------------
.main-contact {
   padding-bottom: 0;
   .room-icon {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      &:last-child {
         margin-bottom: 30px;
      }
      .svg {
         width: 27px;
         max-height: 27px;
      }
   }
   .container {
      max-width: 1500px;
   }
   .contact-link {
      display: flex;
   }
   .contact-social {
      justify-content: center;
      display: flex;
      font-size: 25px;
      width: 27px;
   }
}
.main-contact-img {
   height: 70vh;
}
.footer-build {
   height: 100vh;
   .comment {
      .section-paragraph {
         line-height: 100vh;
         text-align: center;
         font-size: 30px;
         margin-bottom: 0;
      }
   }
   .footer {
      position: fixed;
      width: 100%;
      bottom: 0;
   }
}
.footer {
   height: 50px;
   background-color: $cfooter;
   display: flex;
   align-items: center;
   justify-content: center;
   .pfooter {
      color: white;
      text-align: center;
      font-size: 14px;
   }
}
// -----------------------------CENNIK------------------------------
.main-pricelist {
   text-align: center;
   background: $rectangle, url(../img/34.jpg);
   background-size: cover;
   background-position: 45%;
   background-attachment: fixed;
   height: 250px;
   .section-paragraph {
      line-height: 100px;
   }
}
// ------------------------2-ONAS-------------------------------------
.menu-color {
   background-color: rgba(0, 0, 0, 0.7);
}
.section-about-text {
   // background: $rectangle, url(../img/2onas.jpg); // background-size: cover; // background-position: 45%;
   .section-title {
      margin-top: 50px;
   }
}
.section-square {
   padding-top: 30px;
   padding-bottom: 30px;
   .section-paragraph {
      margin-bottom: 0;
      padding-left: 10px;
   }
   .square-icon {
      margin-right: 15px;
   }
   .checked {
      width: 16px;
      height: 16px;
   }
}
.section-square-1,
.section-square-3,
.section-square-5,
.section-square-7 {
   background-color: $square-color;
}
// -------------------------------2-POKOJE--------------------------
.menu-color-pokoje {
   background-color: rgba(0, 0, 0, 0.7);
}
.room-margin {
   padding-top: 60px;
}
.room-container-text {
   max-width: 400px;
   margin: 0 auto;
}
.section-room {
   padding-top: 30px;
   padding-bottom: 30px;
}
.section-room-icon {
   width: 28px;
   height: 28px;
}
.room-paragraph {
   padding-left: 15px;
}
.room-signature {
   margin-top: 10px;
}
.room-list {
   margin-bottom: 5px;
}
.section-room-img {
   padding: 0;
   max-width: 400px;
   margin: 0 auto;
}
.section-room-sample {
   padding: 0;
   max-width: 400px;
   margin: 0 auto;
}
.room-bottom-text {
   text-align: center;
}
// .room-section {
// .section-title.section-title-room {
// padding-top: 80px;
// .section-title {
// padding-top: 30px;
// }
// .room-checklist {
// display: flex;
// align-items: center;
// justify-content: left;
// margin-bottom: 20px;
// .room-paragraph {
// line-height: 1.6;
// font-weight: 400;
// }
// .room-svg-checlist {
// width: $svgwidth;
// max-height: $svgheight;
// margin-right: 15px;
// }
// .
// }
// .room-photo {
// width: 100%;
// height: 100%;
// padding: 0;
// .img-photo {
// width: 100%;
// display: block;
// }
// }
// .room-paragraph-footer {
// margin: 20px 0;
// }
// }
// --------------------------------2-ATRAKCJE-----------------------
.attraction-map {
   display: block;
   width: 100%;
   height: calc(50vh + 60px);
   padding-top: 60px;
   border: none;
   // margin-bottom: 15px;
}
.castle-description {
   margin-top: 15px;
   margin-bottom: 40px;
   // text-align: center;
}
.castle-img-div {
   width: 80%;
   margin: 0 auto;
   margin-bottom: 25px;
   // border-radius: 10px; // overflow: hidden; // max-height: 200px;
}
.castle-img {
   display: block;
   width: auto;
   max-width: 100%;
   height: auto;
   max-height: 250px;
   margin: 0 auto;
}
.castle-img.castle-movie {
   display: block;
   width: 100%;
   max-width: 100%;
   height: 100%;
   min-height: 250px;
   margin: 0 auto;
}
.chenonceaux-text {
   background: $rectangle3, url(../img/01chenonceaux.jpg);
   background-size: cover;
   background-position: 40%; // background-attachment: fixed;
}
.amboise-text {
   background: $rectangle3, url(../img/03amboise.jpg);
   background-size: cover;
   background-position: center 20%;
   // background-attachment: fixed;
}
.villandry-text {
   background: $rectangle3, url(../img/05vilandry.jpg);
   background-size: cover;
   background-position: center top;
   // background-attachment: fixed;
}
.kayak-text {
   background: $rectangle, url(../img/08kayak.jpg);
   background-size: cover;
   background-position: center top;
   // background-attachment: fixed;
}
.golf-text {
   background: $rectangle, url(../img/09golf.jpg);
   background-size: cover;
   background-position: center top;
   // background-attachment: fixed;
}
.balloon-text {
   background: $rectangle3, url(../img/11balloon.jpg);
   background-size: cover;
   background-position: center;
   // background-attachment: fixed;
}
.section .section-paragraph:last-child {
   margin-bottom: 0;
}
.castle .section-paragraph:last-child {
   margin-bottom: 0;
}
// // -----------------------------MEDIA----------------------------
@media screen and (min-width: 576px) {
   .container-main {
      max-width: 540px;
   }
   .main-about,
   .main-gallery,
   .main-contact {
      background-size: 45%;
   }
   .icon-p {
      font-size: 17px;
   }
   .svg {
      width: 32px;
      max-height: 32px;
   }
   .main-home {
      .scroll {
         font-size: 13px;
      }
   }
   .main-about {
      .svg {
         width: 35px;
         max-height: 35px;
      }
   }
   // -------------------------------SLIDESHOW-------------------------------
   .slideshow {
      .main-text {
         font-size: 34px;
         &-second {
            font-size: 23px;
         }
      }
   }
   // -----------------------------------2POKOJE------------------------
   .section-room {
      padding-top: 0px;
      padding-bottom: 0px;
   }
   .room-container-text {
      max-width: none;
      margin: 0;
   }
   .section-room-img {
      padding: 0;
      max-width: none;
      margin: 0 auto;
   }
   .section-room-sample {
      padding: 0;
      max-width: none;
      margin: 0 auto;
   }
   .room-bottom-text {
      padding-top: 30px;
      padding-bottom: 30px;
   }
   // -------------------------------2-ATRAKCJE-----------------------
   .chenonceaux-text {
      background-position: 40%;
   }
   .amboise-text {
      background-position: 40% 30%;
   }
   .villandry-text {
      background-position: center 5%;
   }
   .kayak-text {
      background-position: center center;
   }
   .golf-text {
      background-position: center center;
   }
   .balloon-text {
      background-position: center 65%;
   }
}
@media screen and (min-width: 768px) {
   .container-main {
      max-width: 720px;
   }
   .main-about,
   .main-gallery,
   .main-contact {
      background-size: 30%;
   }
   body {
      font-size: 19px;
   }
   .svg {
      width: 36px;
      max-height: 36px;
   }
   .section {
      padding-top: 40px;
      padding-bottom: 40px;
   }
   .castle {
      padding-top: 40px;
      padding-bottom: 40px;
   }
   .main-home {
      .scroll {
         font-size: 14px;
      }
      // -------------------------------SLIDESHOW-------------------------------
      .slideshow {
         .main-text {
            font-size: 36px;
            &-second {
               font-size: 24px;
            }
         }
      }
   }
   .main-about {
      .svg {
         width: 38px;
         max-height: 38px;
      }
   }
   .main-contact {
      padding-bottom: 0;
   }
   .main-contact-img {
      height: 400px;
   }
   // --------------------------------2onas---------------------
   .section-about-text {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.7);
   }
   .section-square-1,
   .section-square-4,
   .section-square-5,
   .section-square-8 {
      background-color: $square-color;
   }
   .section-square-2,
   .section-square-3,
   .section-square-6,
   .section-square-7 {
      background-color: white;
   }
   // -----------------------------------2POKOJE----------------------
}
@media screen and (min-width: 992px) {
   .container-main {
      max-width: 960px;
   }
   .icon-p {
      font-size: 18px;
   }
   .menu {
      background-color: transparent;
   }
   .top {
      background-color: rgba(0, 0, 0, 0.5);
      height: 70px;
      justify-content: space-between;
      color: white;
      .logo {
         order: 1;
         flex: 1;
         span {
            font-size: 17px;
         }
         p {
            padding: 12px 0 10px;
            font-size: 23px;
         }
      }
      .social-icons {
         order: 2;
         flex: 1;
         display: flex;
         justify-content: flex-end;
         padding: 10px 20px 0 0;
         .fa-instagram {
            padding-left: 20px;
            border-left: 1px solid grey;
            font-size: 16px;
            transition: linear 0.2s all;
            &:hover {
               color: $hover;
            }
         }
         .fa-facebook-f {
            padding-right: 20px;
            font-size: 16px;
            transition: linear 0.2s all;
            &:hover {
               color: $hover;
            }
         }
      }
      .contact-icons {
         flex: 1;
         display: flex;
         padding: 10px 0 0 20px;
         .message {
            display: flex;
            padding-right: 20px;
            justify-content: flex-start;
            align-items: flex-start;
            div {
               display: flex;
               align-items: center;
               .fa-envelope {
                  padding-right: 10px;
                  font-size: 16px;
               }
               p {
                  padding-right: 20px;
                  border-right: 1px solid grey;
                  font-size: 14px;
               }
            }
         }
         .telephone {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            div {
               display: flex;
               align-items: center;
               .fa-phone {
                  padding-left: 10px;
                  transform: rotateY(180deg);
                  font-size: 16px;
               }
               p {
                  font-size: 14px;
               }
            }
         }
      }
   }
   .navicon {
      display: none;
   }
   .toggle {
      height: auto;
      margin-top: 70px;
      border-top: 2px solid rgba(255, 255, 255, 0.1);
      background: none;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      transition: none;
      transform: translateY(0);
      &--active {
         transition: none;
         transform: translateY(0);
      }
      &__menu {
         height: auto;
         margin-top: 0px;
         flex-direction: row;
         .first-li {
            margin-top: 0;
            border-top: none;
         }
         li {
            width: auto;
            padding: 0;
            margin: 0;
            border-bottom: none;
            a {
               transition: linear 0.2s color;
               margin: 5px 40px;
               font-size: 17px;
            }
         }
      }
   }
   .main-home {
      .scroll {
         font-size: 12px;
      }
      // -------------------------------SLIDESHOW-------------------------------
      .slideshow {
         .main-text {
            font-size: 42px;
            &-second {
               font-size: 20px;
            }
         }
      }
   }
   // --------------------------------Zdjecia-----------------------------
   .big-img {
      display: block;
      height: auto;
      width: 100%;
   }
   .main-contact.section {
      padding-bottom: 0;
      .room-icon {
         display: flex;
         height: $svgwidth;
         align-items: center;
      }
   }
   .main-contact-img {
      height: 425px;
   }
   // ----------------------------------------2-ONAS------------------------------------
   // .section-square-1,
   // .section-square-4,
   // .section-square-6,
   // .section-square-8 {
   // background-color: $square-color;
   // }
   // .section-square-1,
   // .section-square-3,
   // .section-square-5,
   // .section-square-7 {
   // background-color: white;
   // }
   .toggle-color,
   .top-color {
      background-color: rgba(0, 0, 0, 0.7);
   }
   .toggle-color {
      border-top: 2px solid rgba(255, 255, 255, 0.06);
   }
   .section-about-text {
      .section-title {
         margin-top: 105px;
      }
   }
   .section-square-2 {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
   }
   // --------------------------------------2-POKOJE-----------------------------
   .toggle-color-pokoje,
   .top-color-pokoje {
      background-color: rgba(0, 0, 0, 0.7);
   }
   .room-margin {
      padding-top: 117px;
   }
   // ---------------------------------------2-ATRAKCJE---------------------
   .castle {
      padding: 0;
      .big-margin {
         margin: 0 15px;
      }
   }
   .chenonceaux-text,
   .amboise-text,
   .villandry-text,
   .kayak-text,
   .golf-text,
   .balloon-text {
      background: none;
   }
   .attraction-map {
      height: calc(45vh + 117px);
      padding-top: 117px;
   }
   .castle-description {
      text-align: center;
   }
   // ---------------------BOOTSTRAP---------------------------------
   .order-lg-first {
      -ms-flex-order: -1;
      order: -1;
   }
   .order-lg-last {
      -ms-flex-order: 26;
      order: 26;
   }
   .order-lg-0 {
      -ms-flex-order: 0;
      order: 0;
   }
   .order-lg-1 {
      -ms-flex-order: 1;
      order: 1;
   }
   .order-lg-2 {
      -ms-flex-order: 2;
      order: 2;
   }
   .order-lg-3 {
      -ms-flex-order: 3;
      order: 3;
   }
   .order-lg-4 {
      -ms-flex-order: 4;
      order: 4;
   }
   .order-lg-5 {
      -ms-flex-order: 5;
      order: 5;
   }
   .order-lg-6 {
      -ms-flex-order: 6;
      order: 6;
   }
   .order-lg-7 {
      -ms-flex-order: 7;
      order: 7;
   }
   .order-lg-8 {
      -ms-flex-order: 8;
      order: 8;
   }
   .order-lg-9 {
      -ms-flex-order: 9;
      order: 9;
   }
   .order-lg-10 {
      -ms-flex-order: 10;
      order: 10;
   }
   .order-lg-11 {
      -ms-flex-order: 11;
      order: 11;
   }
   .order-lg-12 {
      -ms-flex-order: 12;
      order: 12;
   }
   .order-lg-13 {
      -ms-flex-order: 13;
      order: 13;
   }
   .order-lg-14 {
      -ms-flex-order: 14;
      order: 14;
   }
   .order-lg-15 {
      -ms-flex-order: 15;
      order: 15;
   }
   .order-lg-16 {
      -ms-flex-order: 16;
      order: 16;
   }
   .order-lg-17 {
      -ms-flex-order: 17;
      order: 17;
   }
   .order-lg-18 {
      -ms-flex-order: 8;
      order: 18;
   }
   .order-lg-19 {
      -ms-flex-order: 19;
      order: 19;
   }
   .order-lg-20 {
      -ms-flex-order: 20;
      order: 20;
   }
   .order-lg-21 {
      -ms-flex-order: 21;
      order: 21;
   }
   .order-lg-22 {
      -ms-flex-order: 22;
      order: 22;
   }
   .order-lg-23 {
      -ms-flex-order: 23;
      order: 23;
   }
   .order-lg-24 {
      -ms-flex-order: 24;
      order: 24;
   }
   .order-lg-25 {
      -ms-flex-order: 25;
      order: 25;
   }
}
@media screen and (min-width: 1200px) {
   .main-pricelist {
      border-top: none;
      border-bottom: none;
      height: auto;
      background: none;
      .section-paragraph {
         line-height: 1.5;
      }
   }
   .main-contact,
   .main-gallery,
   .main-pricelist,
   .section-room-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 666px;
   }
   .main-atraction-img {
      padding-top: 0px;
   }
   .container-main {
      padding-left: 0;
      padding-right: 0;
      max-width: 2000px;
   }
   body {
      font-size: 16px;
   }
   .icon-p {
      font-size: 15px;
   }
   .section {
      padding: 0;
   }
   .svg {
      width: 32px;
      max-height: 32px;
   }
   .main-about {
      padding-top: 0px;
      .svg {
         width: 33px;
         max-height: 33px;
      }
   }
   .main-gallery {
      .gallery-signature {
         display: flex;
         justify-content: center;
      }
      .gallery-paragraph {
         display: block;
         border: none;
         padding: 0;
         margin: 0;
      }
   }
   .main-room {
      background: none;
   }
   .main-atraction {
      background: none;
   }
   .big-margin {
      margin: 0 70px;
   }
   .castle {
      .big-margin {
         margin: 0 70px;
      }
   }
   // -----------------------------------2-ONAS--------------------------------------
   .section-square-1,
   .section-square-2,
   .section-square-3 {
      // border-top: 1px solid rgba(0, 0, 0, 0.7);
   }
   .section-about {
      margin-top: 117px;
   }
   .section-square-2 {
      border-top: none;
   }
   .section-about-text {
      background: none; // margin-top: 0;
      .section-title {
         margin-top: 0px;
      }
   }
   .section-room-text {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   }
   .sr {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
   }
   // -------------------------------2-ATRAKCJE-------------------------
   // ---------------------BOOTSTRAP---------------------------------
   .order-xl-first {
      -ms-flex-order: -1;
      order: -1;
   }
   .order-xl-last {
      -ms-flex-order: 26;
      order: 26;
   }
   .order-xl-0 {
      -ms-flex-order: 0;
      order: 0;
   }
   .order-xl-1 {
      -ms-flex-order: 1;
      order: 1;
   }
   .order-xl-2 {
      -ms-flex-order: 2;
      order: 2;
   }
   .order-xl-3 {
      -ms-flex-order: 3;
      order: 3;
   }
   .order-xl-4 {
      -ms-flex-order: 4;
      order: 4;
   }
   .order-xl-5 {
      -ms-flex-order: 5;
      order: 5;
   }
   .order-xl-6 {
      -ms-flex-order: 6;
      order: 6;
   }
   .order-xl-7 {
      -ms-flex-order: 7;
      order: 7;
   }
   .order-xl-8 {
      -ms-flex-order: 8;
      order: 8;
   }
   .order-xl-9 {
      -ms-flex-order: 9;
      order: 9;
   }
   .order-xl-10 {
      -ms-flex-order: 10;
      order: 10;
   }
   .order-xl-11 {
      -ms-flex-order: 11;
      order: 11;
   }
   .order-xl-12 {
      -ms-flex-order: 12;
      order: 12;
   }
   .order-xl-13 {
      -ms-flex-order: 13;
      order: 13;
   }
   .order-xl-14 {
      -ms-flex-order: 14;
      order: 14;
   }
   .order-xl-15 {
      -ms-flex-order: 15;
      order: 15;
   }
   .order-xl-16 {
      -ms-flex-order: 16;
      order: 16;
   }
   .order-xl-17 {
      -ms-flex-order: 17;
      order: 17;
   }
   .order-xl-18 {
      -ms-flex-order: 8;
      order: 18;
   }
   .order-xl-19 {
      -ms-flex-order: 19;
      order: 19;
   }
   .order-xl-20 {
      -ms-flex-order: 20;
      order: 20;
   }
   .order-xl-21 {
      -ms-flex-order: 21;
      order: 21;
   }
   .order-xl-22 {
      -ms-flex-order: 22;
      order: 22;
   }
   .order-xl-23 {
      -ms-flex-order: 23;
      order: 23;
   }
   .order-xl-24 {
      -ms-flex-order: 24;
      order: 24;
   }
   .order-xl-25 {
      -ms-flex-order: 25;
      order: 25;
   }
}
@media screen and (min-width: 1400px) {
   .big-margin {
      margin: 0 100px;
   }
   .castle {
      .big-margin {
         margin: 0 100px;
      }
   }
}
@media screen and (min-width: 1600px) {
   .main-gallery-text {
      position: absolute;
      z-index: 101;
      transform: translate(100%, calc(200% - 6px));
      background-color: white;
   }
   .container {
      margin: 0 auto;
   }
   .main-contact-text {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
   }
   .main-pricelist-img {
      display: none !important;
   }
   .bla {
      background-size: 15%;
   }
   body {
      font-size: 17px;
   }
   .icon-p {
      font-size: 16px;
   }
   .big-margin {
      margin: 0 25px;
   }
   .castle {
      .big-margin {
         margin: 0 25px;
      }
   }
   .svg {
      width: 30px;
      max-height: 30px;
   }
   .main-about {
      padding-top: 0px;
      .svg {
         width: 32px;
         max-height: 32px;
      }
   }
   // ------------------------------------------2-ONAS--------------------------------
   .section-square-4 {
      // border-top: 1px solid rgba(0, 0, 0, 0.7);
   }
   .section-square-2,
   .section-square-4,
   .section-square-6,
   .section-square-8 {
      background-color: $square-color;
   }
   .section-square-1,
   .section-square-3,
   .section-square-5,
   .section-square-7 {
      background-color: white;
   }
   .section-about-text {
      .section-title {
         margin-top: 15px;
      }
   }
   .col-xxl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
   }
   .col-xxl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
   }
   .col-xxl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
   }
   .col-xxl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
   }
   .col-xxl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
   }
   .col-xxl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
   }
   .col-xxl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
   }
   .col-xxl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
   }
   .col-xxl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
   }
   .col-xxl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
   }
   .col-xxl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
   }
   .col-xxl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
   }
   .col-xxl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
   }
   .col-xxl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
   }
   .order-xxl-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
   }
   .order-xxl-last {
      -webkit-box-ordinal-group: 34;
      -ms-flex-order: 34;
      order: 34;
   }
   .order-xxl-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
   }
   .order-xxl-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
   }
   .order-xxl-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
   }
   .order-xxl-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
   }
   .order-xxl-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
   }
   .order-xxl-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
   }
   .order-xxl-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
   }
   .order-xxl-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
   }
   .order-xxl-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
   }
   .order-xxl-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
   }
   .order-xxl-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
   }
   .order-xxl-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
   }
   .order-xxl-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
   }
   .order-xxl-13 {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
   }
   .order-xxl-14 {
      -webkit-box-ordinal-group: 15;
      -ms-flex-order: 14;
      order: 14;
   }
   .order-xxl-15 {
      -webkit-box-ordinal-group: 16;
      -ms-flex-order: 15;
      order: 15;
   }
   .order-xxl-16 {
      -webkit-box-ordinal-group: 17;
      -ms-flex-order: 16;
      order: 16;
   }
   .order-xxl-17 {
      -webkit-box-ordinal-group: 18;
      -ms-flex-order: 17;
      order: 17;
   }
   .order-xxl-18 {
      -webkit-box-ordinal-group: 19;
      -ms-flex-order: 18;
      order: 18;
   }
   .order-xxl-19 {
      -webkit-box-ordinal-group: 20;
      -ms-flex-order: 19;
      order: 19;
   }
   .order-xxl-20 {
      -webkit-box-ordinal-group: 21;
      -ms-flex-order: 20;
      order: 20;
   }
   .order-xxl-21 {
      -webkit-box-ordinal-group: 22;
      -ms-flex-order: 21;
      order: 21;
   }
   .order-xxl-22 {
      -webkit-box-ordinal-group: 23;
      -ms-flex-order: 22;
      order: 22;
   }
   .order-xxl-23 {
      -webkit-box-ordinal-group: 24;
      -ms-flex-order: 23;
      order: 23;
   }
   .order-xxl-24 {
      -webkit-box-ordinal-group: 25;
      -ms-flex-order: 24;
      order: 24;
   }
   .order-xxl-25 {
      -webkit-box-ordinal-group: 26;
      -ms-flex-order: 25;
      order: 25;
   }
   .order-xxl-26 {
      -webkit-box-ordinal-group: 27;
      -ms-flex-order: 26;
      order: 26;
   }
   .order-xxl-27 {
      -webkit-box-ordinal-group: 28;
      -ms-flex-order: 27;
      order: 27;
   }
   .order-xxl-28 {
      -webkit-box-ordinal-group: 29;
      -ms-flex-order: 28;
      order: 28;
   }
   .order-xxl-29 {
      -webkit-box-ordinal-group: 30;
      -ms-flex-order: 29;
      order: 29;
   }
   .order-xxl-30 {
      -webkit-box-ordinal-group: 31;
      -ms-flex-order: 30;
      order: 30;
   }
   .order-xxl-31 {
      -webkit-box-ordinal-group: 32;
      -ms-flex-order: 31;
      order: 31;
   }
   .order-xxl-32 {
      -webkit-box-ordinal-group: 33;
      -ms-flex-order: 32;
      order: 32;
   }
   .order-xxl-33 {
      -webkit-box-ordinal-group: 34;
      -ms-flex-order: 33;
      order: 33;
   }
   .offset-xxl-0 {
      margin-left: 0;
   }
   .offset-xxl-1 {
      margin-left: 8.333333%;
   }
   .offset-xxl-2 {
      margin-left: 16.666667%;
   }
   .offset-xxl-3 {
      margin-left: 25%;
   }
   .offset-xxl-4 {
      margin-left: 33.333333%;
   }
   .offset-xxl-5 {
      margin-left: 41.666667%;
   }
   .offset-xxl-6 {
      margin-left: 50%;
   }
   .offset-xxl-7 {
      margin-left: 58.333333%;
   }
   .offset-xxl-8 {
      margin-left: 66.666667%;
   }
   .offset-xxl-9 {
      margin-left: 75%;
   }
   .offset-xxl-10 {
      margin-left: 83.333333%;
   }
   .offset-xxl-11 {
      margin-left: 91.666667%;
   }
   .d-xxl-block {
      display: block !important;
   }
}
@media screen and (min-width: 1800px) {
   .big-margin {
      margin: 0 50px;
   }
   .castle {
      .big-margin {
         margin: 0 50px;
      }
   }
}
@media screen and (max-height: 500px) and (max-width: 991px) {
   .toggle {
      display: flex;
      position: absolute;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.6) 0%,
            rgba(0, 0, 0, 0.6) 100%
         ),
         url(../img/main2.jpg) 25%;
      background-size: cover;
      display: block;
      opacity: 0;
      transition: ease-in 0.5s opacity;
      transform: translateY(-200%);
      &.fixed {
         position: fixed;
         margin: 0;
         background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0.7) 100%
         );
      }
      &--active {
         display: flex;
         flex-direction: column;
         justify-content: center;
         opacity: 1;
         transition: ease-in 0.5s opacity;
         transform: translateY(0);
      }
      &__menu {
         padding: 0;
         margin: 0;
         margin-top: 70px;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-content: center;
         flex-wrap: wrap;
         font-size: 30px;
         .contact-menu {
            align-self: flex-end;
         }
         li {
            width: 40%;
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            a {
               text-decoration: none;
               color: white;
               display: block;
               text-align: center;
               font-size: 22px;
               transition: linear 0.2s all;
               padding: 5px 0;
               &:hover {
                  color: $hover;
               }
            }
         }
      }
      .submenu {
         color: white;
         font-size: 16px;
         display: flex;
         justify-content: center;
         flex-direction: row;
         align-items: center;
         align-content: center;
         margin-top: 50px;
         .facebook {
            order: 2;
            margin: 0 50px;
         }
         .message {
            order: 3;
            div {
               display: flex;
               align-items: center;
               .fa-envelope {
                  margin-right: 10px;
               }
            }
         }
         .telephone {
            order: 1;
            div {
               display: flex;
               align-items: center;
               .fa-phone {
                  margin-right: 10px;
                  transform: rotateY(180deg);
               }
            }
         }
      }
   }
}
// // -----------------------------ANIMATION---------------------------
@keyframes imageAnimation {
   0% {
      opacity: 0.95;
   }
   5% {
      opacity: 1;
   }
   33.3333% {
      opacity: 1;
      transform: scale(1.035);
   }
   35% {
      opacity: 0;
   }
   100% {
      opacity: 0;
      transform: scale(1);
   }
}
@keyframes titleAnimation {
   0% {
      opacity: 0.8;
      animation-timing-function: ease-in;
   }
   5% {
      opacity: 1;
      animation-timing-function: ease-out;
   }
   33.3333% {
      opacity: 1;
   }
   33.3334% {
      opacity: 0;
   }
   100% {
      opacity: 0;
   }
}
@keyframes scroll {
   0% {
      transform: translateY(-15px);
   }
   50% {
      transform: translateY(0px);
   }
   100% {
      transform: translateY(15px);
   }
}
